<template>
<div class="container-fluid">
  <div class="p-lg-2">
    <h2 class="roboto bold">My Profile</h2>
    <b-form ref="form" class="pt-5" @submit.prevent>
      <!-- ACCOUNT INFORMATION -->
      <h2 class="subtitle roboto bold">Account Information</h2>
      <hr />
      <!-- Email -->
      <div class="row mb-2">
        <div class="col-md-2 pt-2">Email <b style="color: red">*</b></div>
        <div class="col-md-7">
          <InputText
            type="email"
            v-model="$v.formData.email.$model"
            placeholder="Email"
            disabled
            :classComponent="{
              'is-invalid-custom': $v.formData.email.$error,
            }"
            :invalidMessage="[
              !$v.formData.email.required ? 'Email is required.' : null,
            ]"
          />
        </div>
      </div>
      <!-- Bank Account Number -->
      <div class="row mb-2">
        <div class="col-md-2 pt-2 text-nowrap">
          Bank Account Number <b style="color: red">*</b>
        </div>
        <div class="col-md-7">
          <InputText
            type="text"
            v-model="$v.formData.bankAccountNumber.$model"
            placeholder="Bank Account Number"
            :classComponent="{
              'is-invalid-custom': $v.formData.bankAccountNumber.$error,
            }"
            :invalidMessage="[
              !$v.formData.bankAccountNumber.required
                ? 'Bank Account Number is required.'
                : null,
              !$v.formData.bankAccountNumber.numeric
                ? 'Your Bank Account Number is invalid. Must be numeric!'
                : null,
            ]"
          />
        </div>
      </div>
      <!-- Bank Account Name -->
      <div class="row mb-2">
        <div class="col-md-2 pt-2 text-nowrap">
          Bank Account Name <b style="color: red">*</b>
        </div>
        <div class="col-md-7">
          <InputText
            type="text"
            v-model="$v.formData.bankAccountName.$model"
            placeholder="Bank Account Name"
            :classComponent="{
              'is-invalid-custom': $v.formData.bankAccountName.$error,
            }"
            :invalidMessage="[
              !$v.formData.bankAccountName.required
                ? 'Bank Account Name is required.'
                : null,
              !$v.formData.bankAccountName.namePattern
                ? 'Your Bank Account Name is invalid.'
                : null,
            ]"
          />
        </div>
      </div>
      <div class="shadow mb-5">&nbsp;</div>

      <!-- BASIC INFORMATION -->
      <h2 class="subtitle roboto bold">Basic Information</h2>
      <!-- Name -->
      <div class="row mb-2">
        <div class="col-md-2 pt-2">Name <b style="color: red">*</b></div>
        <div class="col-md-7">
          <InputText
            type="text"
            v-model="$v.formData.name.$model"
            placeholder="Name"
            :classComponent="{
              'is-invalid-custom': $v.formData.name.$error,
            }"
            :invalidMessage="[
              !$v.formData.name.required ? 'Name is required.' : null,
              !$v.formData.name.alphaNumericPattern ? 'Your Name is invalid.' : null,
            ]"
          />
        </div>
      </div>
      <!-- Merchant URL -->
      <div class="row mb-2">
        <div class="col-md-2 pt-2">Merchant URL</div>
        <div class="col-md-7">
          <InputText
            type="text"
            v-model="$v.formData.merchantURL.$model"
            placeholder="Merchant URL"
            :classComponent="{
              'is-invalid-custom': $v.formData.merchantURL.$error,
            }"
            :invalidMessage="[
              !$v.formData.merchantURL.url
                ? 'Invalid URL. (E.g. : https://storeURL.com)'
                : null,
            ]"
          />
        </div>
      </div>
      <!-- Phone -->
      <div class="row mb-2">
        <div class="col-md-2 pt-2">Phone <b style="color: red">*</b></div>
        <div class="col-md-7">
          <InputText
            type="text"
            v-model="$v.formData.phone.$model"
            placeholder="Phone Number"
            :classComponent="{
              'is-invalid-custom': $v.formData.phone.$error,
            }"
            :invalidMessage="[
              !$v.formData.phone.required
                ? 'Phone number is required.'
                : null,
              !$v.formData.phone.phonePattern
                ? 'Your Phone Number is invalid! (E.g. : +628xxxxxxxx)'
                : null,
              !$v.formData.phone.minLength && $v.formData.phone.phonePattern
                ? 'Phone Number must have at least ' +
                  $v.formData.phone.$params.minLength.min +
                  ' characters! ' +
                  ($v.formData.phone.$params.minLength.min -
                    $v.formData.phone.$model.length) +
                  ' characters left'
                : null,
              !$v.formData.phone.maxLength && $v.formData.phone.phonePattern
                ? 'Phone Number must have less than ' +
                  $v.formData.phone.$params.maxLength.max +
                  ' characters!'
                : null,
            ]"
          />
        </div>
      </div>
      <div class="shadow mb-5">&nbsp;</div>

      <template>
        <div class="align-items-center d-flex">
          <h2 class="subtitle roboto bold m-0">Operational Time</h2>
          <button class="btn btn-wz-gold ml-auto ml-md-2" v-b-modal.modal-operational-time>
            <font-awesome icon="edit" /> Edit
          </button>
        </div>
        <hr>
        <TableOperationalTime v-model="datetime" :disabled="true" classComponent="w-md-auto" />
        <ModalOperationalTime @dataChanged="datetime = $event" />
        <div class="shadow mb-5">&nbsp;</div>
      </template>

      <template v-if="merchantData.merchant_pick_up_point_platform.name == 'OWN'">
        <!-- STORE INFORMATION -->
        <h2 class="subtitle roboto bold">Store Information</h2>
        <hr />
        <!-- Courier List -->
        <div class="row mb-2">
          <div class="col-md-2 pt-2">
            Courier(s) <b style="color: red">*</b>
          </div>
          <div class="col-md-7">
            <div class="row d-flex justify-content-center p-1">
              <div
                class="col-3 p-1"
                v-for="(courier, i) in courierList"
                :key="i"
              >
                <div
                  class="
                    d-flex
                    flex-column
                    align-items-center
                    border
                    p-1
                    cursor-pointer
                  "
                  :class="{
                    'bg-info': handleCourierModel(courier.id, true) != -1,
                  }"
                  @click="handleCourierModel(courier.id)"
                >
                  <img class="w-100" :src="courier.image_url" />
                  <b class="text-center">{{ courier.name }}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Address -->
        <div class="row mb-2">
          <div class="col-md-2 pt-2">Address <b style="color: red">*</b></div>
          <div class="col-md-7">
            <InputText
              type="textarea"
              v-model="$v.formData.address.$model"
              placeholder="Address"
              :classComponent="{
                'is-invalid-custom': $v.formData.address.$error,
              }"
              :invalidMessage="[
                !$v.formData.address.required ? 'Address is required.' : null,
              ]"
            />
          </div>
        </div>
        <!-- Province -->
        <div class="row mb-2">
          <div class="col-md-2 pt-2">Province <b style="color: red">*</b></div>
          <div class="col-md-7">
            <InputSelect
              v-model="$v.formData.provinceId.$model"
              :options="provinceList"
              placeholder="Select Province"
              @input="getDistrictList"
              v-if="provinceList"
              :classComponent="{
                'is-invalid-custom': $v.formData.provinceId.$error,
              }"
              :invalidMessage="[
                !$v.formData.provinceId.provinceIsValid
                  ? 'Province is required.'
                  : null,
              ]"
            />
          </div>
        </div>
        <!-- District  -->
        <div class="row mb-2">
          <div class="col-md-2 pt-2">District <b style="color: red">*</b></div>
          <div class="col-md-7">
            <InputSelect
              v-model="$v.formData.districtId.$model"
              :options="districtList"
              placeholder="Select District"
              @input="getSubDistrictList"
              v-if="districtList"
              :classComponent="{
                'is-invalid-custom': $v.formData.districtId.$error,
              }"
              :invalidMessage="[
                !$v.formData.districtId.districtIsValid
                  ? 'District is required.'
                  : null,
              ]"
            />
          </div>
        </div>
        <!-- Sub District  -->
        <div class="row mb-2">
          <div class="col-md-2 pt-2">
            Sub District <b style="color: red">*</b>
          </div>
          <div class="col-md-7">
            <InputSelect
              v-model="$v.formData.subDistrictId.$model"
              :options="subDistrictList"
              placeholder="Select Sub District"
              v-if="subDistrictList"
              :classComponent="{
                'is-invalid-custom': $v.formData.subDistrictId.$error,
              }"
              :invalidMessage="[
                !$v.formData.subDistrictId.subDisctrictIsValid
                  ? 'Sub district is required.'
                  : null,
              ]"
            />
          </div>
        </div>
        <!-- Postal Code -->
        <div class="row mb-2">
          <div class="col-md-2 pt-2">
            Postal Code <b style="color: red">*</b>
          </div>
          <div class="col-md-7">
            <InputText
              type="text"
              v-model="$v.formData.postal.$model"
              placeholder="Postal Code"
              :classComponent="{
                'is-invalid-custom': $v.formData.postal.$error,
              }"
              :invalidMessage="[
                !$v.formData.postal.required
                  ? 'Postal code is required.'
                  : null,
                !$v.formData.postal.numeric
                  ? 'Your postal code is invalid. Must be numeric!'
                  : null,
              ]"
            />
          </div>
        </div>
      </template>

      <hr />
      <div class="text-right">
        <button type="button" class="btn btn-info" @click="submitData()">
          Save
        </button>
      </div>
    </b-form>
  </div>
</div>
</template>
<script>
const InputText = () => import("@/components/formCustom/InputText");
const InputSelect = () => import("@/components/formCustom/InputSelect");
import { regex, toaster, cookieMan } from "@/_helpers";
import { required, numeric, minLength, maxLength, url, helpers } from "vuelidate/lib/validators";
import ModalOperationalTime from "@/components/operationalTime/ModalOperationalTime";
import TableOperationalTime from "@/components/operationalTime/TableOperationalTime";

// Custom Validation Function Start
const namePattern = helpers.regex("namePattern", regex.namePattern);
const alphaNumericPattern = helpers.regex("alphaNumericPattern", regex.alphaNumericPattern);
const phonePattern = helpers.regex("phonePattern", regex.phonePattern);
const provinceIsValid = function () {
  if (this.formData.provinceId > 0) return true;
  else return false;
};
const districtIsValid = function () {
  if (this.formData.districtId > 0) return true;
  else return false;
};
const subDisctrictIsValid = function () {
  if (this.formData.subDistrictId > 0) return true;
  else return false;
};
// Custom Validation Function End

const initialData = () => ({
  datetime: [],
  formData: {
    email: "",
    bankAccountNumber: "",
    bankAccountName: "",
    name: "",
    merchantURL: "",
    phone: "",
    couriers: [],
    address: "",
    provinceId: 0,
    districtId: 0,
    subDistrictId: 0,
    postal: "",
  },
  provinceList: [],
  districtList: [],
  subDistrictList: [],
  courierList: [],
});

export default {
  name: 'AccountInfo',
  components: {
    InputText,
    InputSelect,
    ModalOperationalTime,
    TableOperationalTime,
  },
  data() {
    return initialData();
  },
  computed: {
    merchantData() {
      return this.$store.getters.getMerchantData;
    },
  },
  validations: {
    formData: {
      email: {
        required,
      },
      bankAccountNumber: {
        required,
        numeric,
      },
      bankAccountName: {
        required,
        namePattern,
      },
      name: {
        required,
        alphaNumericPattern,
      },
      merchantURL: {
        url,
      },
      phone: {
        required,
        phonePattern,
        minLength: minLength(9),
        maxLength: maxLength(14),
      },
      address: {
        required,
      },
      provinceId: {
        provinceIsValid,
      },
      districtId: {
        districtIsValid,
      },
      subDistrictId: {
        subDisctrictIsValid,
      },
      postal: {
        required,
        numeric,
      },
    },
  },
  mounted() {
    this.getFormData();
    this.getProvinceList();
    this.getDistrictList(this.merchantData.merchant_region.id, true);
    this.getSubDistrictList(this.merchantData.merchant_district.id, true);
    this.getCourierList();
  },
  methods: {
    getFormData() {
      const formData = this.formData;
      const merchantData = this.merchantData;
      formData.email = merchantData.merchant_email;
      formData.bankAccountNumber = merchantData.merchant_account_number;
      formData.bankAccountName = merchantData.merchant_account_alias;
      formData.name = merchantData.merchant_full_name;
      formData.merchantURL = merchantData.merchant_url;
      formData.phone = merchantData.merchant_phone;
      formData.couriers = merchantData.merchant_couriers;
      formData.address = merchantData.merchant_address;
      formData.postal = merchantData.merchant_postal;
      this.setArea(
        merchantData.merchant_region.id,
        merchantData.merchant_district.id,
        merchantData.merchant_township.id
      );
    },
    setArea(provinceId, districtId, subDistrictId) {
      if (!this.provinceList && !this.districtList && !this.subDistrictList)
        setTimeout(
          () => this.setArea(provinceId, districtId, subDistrictId),
          300
        );
      else {
        this.formData.provinceId = provinceId;
        this.formData.districtId = districtId;
        this.formData.subDistrictId = subDistrictId;
      }
    },
    async getProvinceList() {
      try {
        const res = await this.$api.cms.getProvinceList();
        if (res.status === 200) this.provinceList = res.data.data;
      } catch (e) {
        console.error(e);
      }
    },
    async getDistrictList(id, isDefault = false) {
      try {
        this.$v.formData.districtId.$touch();
        this.$v.formData.subDistrictId.$touch();
        if (!isDefault) {
          this.formData.districtId = 0;
          this.formData.subDistrictId = 0;
        }
        this.subDistrictList = []; // Clear sub district after change province
        const res = await this.$api.cms.getDistrictList(id);
        if (res.status === 200) this.districtList = res.data.data;
      } catch (e) {
        console.error(e);
      }
    },
    async getSubDistrictList(id, isDefault = false) {
      try {
        this.$v.formData.subDistrictId.$touch();
        if (!isDefault) {
          this.formData.subDistrictId = 0;
        }
        const res = await this.$api.cms.getSubDistrictList(id);
        if (res.status === 200) this.subDistrictList = res.data.data;
      } catch (e) {
        console.error(e);
      }
    },
    async getCourierList() {
      try {
        const res = await this.$api.cms.getCourierList();
        if (res.status === 200) this.courierList = res.data.data;
      } catch (e) {
        console.error(e);
      }
    },
    async submitData() {
      try {
        this.$v.$touch();
        if (this.$v.$error) {
          toaster.make("Please fill in the form correctly", "danger");
          const errors = Object.keys(this.$v.formData)
            .map((el) => {
              if (!/^\$/.test(el))
                return {
                  form: el,
                  data: this.$v.formData[el],
                };
            })
            .filter((el) => el && el.data.$invalid);
          console.error("Invalid form data", errors);
        } else this.updateMerchantData();
      } catch (e) {
        console.error(e);
      }
    },
    async updateMerchantData() {
      try {
        const formData = JSON.parse(JSON.stringify(this.formData));
        const body = {
          email: formData.email,
          password: "", // set null for password, if not INTERNAL SERVER ERROR(500)
          confirm_password: "", // set null for confirm_password, if not INTERNAL SERVER ERROR(500)
          account_number: formData.bankAccountNumber,
          account_alias: formData.bankAccountName,
          name: formData.name,
          full_name: formData.name,
          url: formData.merchantURL,
          phone: formData.phone,
          shippings: formData.couriers,
          address: formData.address,
          region_id: formData.provinceId,
          district_id: formData.districtId,
          township_id: formData.subDistrictId,
          postal_code: formData.postal,
        };
        const res = await this.$api.user.updateProfile(body);
        if (res.status === 200) {
          toaster.make("Your profile has been updated.", "success");
          this.updateCookiesAndStore();
          this.$router.replace("/")
        } else toaster.make(res.data.message, "danger")
      } catch (e) {
        console.error(e);
      }
    },
    async updateCookiesAndStore() {
      const res = await this.$api.user.getProfile();
      const newProfile = res.data.data;
      const retval = {
        merchant_full_name: newProfile.full_name,
        merchant_email: newProfile.email,
        merchant_account_alias: newProfile.account_alias,
        merchant_account_number: newProfile.account_number,
        merchant_url: newProfile.url,
        merchant_phone: newProfile.phone,
        merchant_couriers: JSON.stringify(
          newProfile.couriers.map(({ id }) =>
            JSON.parse(`{"shipping_id":${id}}`)
          )
        ),
        merchant_address: newProfile.address,
        merchant_region: JSON.stringify(newProfile.region),
        merchant_district: JSON.stringify(newProfile.district),
        merchant_township: JSON.stringify(newProfile.township),
        merchant_postal: newProfile.postal_code,
      };
      this.setCookie(retval);
      // set initial state for vuex store because the data type is object / array (in retval the value for the data is JSON stringfy)
      retval["merchant_couriers"] = newProfile.couriers.map(({ id }) => JSON.parse(`{"shipping_id":${id}}`));
      retval["merchant_region"] = newProfile.region;
      retval["merchant_district"] = newProfile.district;
      retval["merchant_township"] = newProfile.township;

      for (const key in retval) {
        this.$store.dispatch({
          type: "updateMerchantData",
          key: key,
          value: retval[key],
        });
      }
    },
    handleCourierModel(id, get) {
      const index = this.formData.couriers.findIndex(
        (el) => el.shipping_id === id
      );
      if (get) return index;
      if (index == -1) {
        this.formData.couriers.push({ shipping_id: id });
      } else {
        this.formData.couriers.splice(index, 1);
      }
    },
    setCookie(obj) {
      for (let o in obj) {
        cookieMan.put(o, obj[o] || "");
      }
    },
  },
}
</script>
