<template>
  <div class="table-responsive">
    <b-table-simple v-if="!isLoading" hover striped :class="[classComponent]">
      <b-thead head-variant="dark">
        <b-tr>
          <b-th>Hari</b-th>
          <b-th>Keterangan</b-th>
          <b-th>Jam Buka</b-th>
          <b-th>Jam Tutup</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(m, i) in model"
          :key="i"
          :class="{
            'text-secondary': m.$model != null && !m.$model.open && !disabled,
          }"
        >
          <b-th class="align-middle">
            {{ m.$model != null ? m.$model.name : m.name }}
          </b-th>
          <template v-if="!disabled">
            <b-td class="align-middle">
              <MyInputFormSelect
                :id="m.$model.id"
                v-model="m.cat"
                :options="categories"
                @change.native="onChangeCategories($event, m)"
                placeholder="Pilih Status"
                classGroup="mb-0"
                class="my-select-category"
              />
            </b-td>
            <b-td class="align-middle">
              <InputText
                type="time"
                v-model="m.$model.open"
                :max="m.$model.closed"
                :disabled="!(m.cat == '0')"
                @input="$event == '' ? setClosed(m.$model) : {}"
              />
            </b-td>
            <b-td class="align-middle">
              <InputText
                type="time"
                v-model="m.$model.closed"
                :min="m.$model.open"
                :disabled="!(m.cat == '0') || !m.$model.open"
                :classComponent="{ 'border-danger': m.closed.$invalid }"
              />
            </b-td>
          </template>
          <template v-else-if="m.open && m.closed">
            <b-td>{{ getCategory(m.open, m.closed).text }}</b-td>
            <b-td>{{ m.open }}</b-td>
            <b-td>{{ m.closed }}</b-td>
          </template>
          <template v-else>
            <b-td>Libur</b-td>
            <b-td>--:--</b-td>
            <b-td>--:--</b-td>
          </template>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import InputText from "../formCustom/InputText";
import MyInputFormSelect from "../form/MyInputFormSelect.vue";

export default {
  name: "TableOperationalTime",
  props: ["value", "disabled", "classComponent"],
  components: {
    InputText,
    MyInputFormSelect,
  },
  data() {
    return {
      categories: [
        {
          value: "0",
          text: "Kustomisasi",
        },
        {
          value: "1",
          text: "Jam Kerja",
        },
        {
          value: "2",
          text: "24 Jam",
        },
        {
          value: "3",
          text: "Libur",
        },
      ],
      isLoading: true,
    };
  },
  mounted() {
    if (!this.disabled) {
      this.isLoading = true;
      this.fetchData();
    } else {
      this.isLoading = false;
    }
  },
  computed: {
    model: {
      get() {
        return (
          this.value ?? [
            {
              id: 1,
              name: "Senin",
              open: null,
              closed: null,
            },
            {
              id: 2,
              name: "Selasa",
              open: null,
              closed: null,
            },
            {
              id: 3,
              name: "Rabu",
              open: null,
              closed: null,
            },
            {
              id: 4,
              name: "Kamis",
              open: null,
              closed: null,
            },
            {
              id: 5,
              name: "Jumat",
              open: null,
              closed: null,
            },
            {
              id: 6,
              name: "Sabtu",
              open: null,
              closed: null,
            },
            {
              id: 7,
              name: "Minggu",
              open: null,
              closed: null,
            },
          ]
        );
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    fetchData() {
      for (const m in this.model) {
        if (Object.hasOwnProperty.call(this.model, m)) {
          const element = this.model[m];
          this.checkCategory(element.$model, element);
        }
      }
      this.$nextTick(() => {
        this.isLoading = false;
      });
    },
    checkCategory(model, element) {
      element.cat = this.getCategory(model.open, model.closed).value;
    },
    getCategory(open, closed) {
      let cat = "0";
      if (!open && !closed) {
        cat = "3";
      } else if (open == "00:00" && closed == "23:59") {
        cat = "2";
      } else if (open == "08:00" && closed == "17:00") {
        cat = "1";
      }
      return this.categories.find((e) => e.value == cat);
    },
    onChangeCategories(e, m) {
      const val = e.target.value;
      this.isLoading = true;
      m.cat = val;
      switch (val) {
        case "0":
          this.setCustom(m.$model);
          break;
        case "1":
          this.setWorkHrs(m.$model);
          break;
        case "2":
          this.set24Hrs(m.$model);
          break;
        case "3":
          this.setClosed(m.$model);
          break;
        default:
          break;
      }
      this.isLoading = false;
    },
    setClosed(model) {
      model.open = model.closed = "reset";
      this.$nextTick(() => {
        model.open = model.closed = null;
      });
    },
    setWorkHrs(model) {
      model.open = "08:00";
      model.closed = "17:00";
    },
    setCustom(model) {
      const tempOpen = model.open;
      const tempClose = model.closed;
      this.$nextTick(() => {
        model.open = model.closed = null;
        model.open = tempOpen;
        model.closed = tempClose;
      });
    },
    set24Hrs(model) {
      model.open = "00:00";
      model.closed = "23:59";
    },
  },
};
</script>

<style scoped>
.my-select-category {
  width: 130px;
}
</style>
