<template>
  <b-modal
    id="modal-operational-time"
    centered
    scrollable
    hide-header
    hide-footer
  >
    <form @submit.prevent="onSubmit" class="m-0">
      <TableOperationalTime v-model="$v.datetime.$each.$iter" />
      <div class="d-flex justify-content-end">
        <input
          type="reset"
          class="btn btn-secondary"
          value="Cancel"
          @click="
            datetime = JSON.parse(JSON.stringify(initialData));
            $bvModal.hide('modal-operational-time');
          "
        />
        <button class="btn btn-info ml-2">
          Save <font-awesome v-if="isLoading" icon="spinner" spin />
        </button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import { toaster } from "@/_helpers";
import TableOperationalTime from "./TableOperationalTime";
import { requiredIf } from "vuelidate/lib/validators";
const customMinValue = (type) => (value, vm) => {
  const openTime = parseInt(vm[type]?.replace(":", "")),
    closeTime = parseInt(value?.replace(":", ""));

  if (isNaN(openTime) || isNaN(closeTime)) return true;
  return openTime < closeTime;
};

export default {
  name: "ModalOperationalTime",
  components: {
    TableOperationalTime,
  },
  data() {
    return {
      isLoading: false,
      initialData: [],
      datetime: [],
    };
  },
  computed: {
    merchant() {
      return this.$store.getters.getMerchantData;
    },
  },
  validations() {
    return {
      datetime: {
        $each: {
          open: {},
          closed: {
            required: requiredIf((self) => self.open),
            isValid: customMinValue("open"),
          },
          id: {},
          name: {},
        },
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    setDatetime(data) {
      this.datetime = data;
      this.initialData = JSON.parse(JSON.stringify(this.datetime));
      this.$emit("dataChanged", this.initialData);
    },
    async getData() {
      try {
        const res = await this.$api.user.getOperationalTime();
        if (res.status === 200)
          this.setDatetime(
            res.data.data ?? [
              {
                id: 1,
                name: "Senin",
                open: null,
                closed: null,
              },
              {
                id: 2,
                name: "Selasa",
                open: null,
                closed: null,
              },
              {
                id: 3,
                name: "Rabu",
                open: null,
                closed: null,
              },
              {
                id: 4,
                name: "Kamis",
                open: null,
                closed: null,
              },
              {
                id: 5,
                name: "Jumat",
                open: null,
                closed: null,
              },
              {
                id: 6,
                name: "Sabtu",
                open: null,
                closed: null,
              },
              {
                id: 7,
                name: "Minggu",
                open: null,
                closed: null,
              },
            ]
          );
        else toaster.make(res.data.message, "danger");
      } catch (e) {
        console.error(e);
      }
    },
    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();
      if (this.$v.$error)
        toaster.make("Please fill in the form correctly", "danger");
      try {
        const res = await this.$api.user.updateOperationalTime(this.datetime);
        if (res.status === 200) {
          toaster.make(res.data.message, "success");
          this.setDatetime(JSON.parse(JSON.stringify(this.datetime)));
          this.$bvModal.hide("modal-operational-time");
        } else toaster.make(res.data.message, "danger");
      } catch (e) {
        if (e.response) toaster.make(e.response, "danger");
        console.error(e);
      }
      this.isLoading = false;
    },
  },
};
</script>